
<template>
<div class="py-4">
  <div v-if="!isLoading" class="row gy-2">
    <div class="col-12">
      <h5 class="mb-3">Appointment</h5>
      <div v-if="appointment">
        <div class="card mb-5">
          <div class="card-body">
            <div class="row gy-3 justify-content-between align-items-start">
              <div class="col-10 col-sm-8">
                <div class="friendly-id mb-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-clipboard">
                  <path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path>
                  <rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect>
                  </svg>
                  {{appointment.friendly_id}}
                </div>
              </div>
              <div class="col-2 text-sm-end">
                <div class="dropdown no-arrow">
                  <button class="btn btn-sm btn-light dropdown-toggle" type="button" role="button" 
                    data-bs-toggle="dropdown" aria-expanded="false">
                    <i class="ri-more-2-fill"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <button @click.prevent="popupModalUpdate = true" class="dropdown-item" type="button" >
                      Update status </button>
                    </li>
                    <li>
                      <button @click.prevent="collectPayment()" class="dropdown-item" type="button" >
                      Repair & Collected </button>
                    </li>
                    <li>
                      <button @click="initCashPayment()" class="dropdown-item" type="button" >
                      Cash Payment </button>
                    </li>
                    <li>
                      <button @click="getStripeSetup()" class="dropdown-item" type="button" >
                      Update Payment method </button>
                    </li>
                    <li>
                      <button @click.prevent="assignWorker()" class="dropdown-item" type="button" >
                      Assign technician </button>
                    </li>
                    <li>
                      <router-link :to="`/users/${appointment.user.id}`" class="dropdown-item">
                      View customer </router-link>
                    </li>
                    <li><button @click.prevent="deleteItem()" class="dropdown-item" type="button" >
                      Delete </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div class="row gy-3">
              <div class="col-12">
                <strong class="me-1">Status:</strong>
                <span v-html="statusBadge(appointment.status)"> </span>
              </div>
              <div class="col-12">
                Total cost: <strong>${{$filters.money_format(appointment.total_cost)}} USD</strong>
              </div>
              <div class="col-6">
                Scheduled date: <strong> {{$filters.date(appointment.schedule_date)}} </strong>
              </div>
              <div class="col-6">
                Time: <strong>{{$filters.format_time(appointment.window_start)}}</strong>
              </div>
              <div class="col-12" v-if="appointment.payment_method">
                <payment-method-card :item="appointment.payment_method"/>
              </div>
              <div class="col-12">
                <span class="me-2">Instructions:</span>
                <strong> {{appointment.instructions || "..."}} </strong>
              </div>
              <div class="col-12">
                <hr>
                <h5 class="">Repair details</h5>
                <div class="d-flex align-items-center">
                  <img width="80" class="me-2" :src="absoluteUrl(appointment.device.image)" :alt="appointment.device.model" />
                  <div>
                    <div v-if="appointment.repair">
                      <h6 class="mb-1">{{appointment.repair.title}}</h6>
                    </div>
                    <p class="mb-0">{{appointment.device.model}}</p>
                  </div>
                </div>
              </div>
              <div class="col-12" v-if="appointment.store">
                <hr>
                <h5 class="">Store details</h5>
                <div class="">
                  <h6 class="mb-2" >{{ appointment.store.title}}</h6 >
                  <p class="mb-1" v-if="appointment.store.location">{{appointment.store.location.address}}, {{appointment.store.location.city}}, {{appointment.store.location.state}}</p>
                </div> 
              </div>
              <div v-if="appointment.user" class="col-12">
                <hr>
                <h5 class="">Customer details</h5>
                <div>
                  <p class="mb-1">{{ appointment.user.email }} / {{appointment.user.mobile}}</p>
                  <p class="mb-0">{{appointment.user.first_name}} {{appointment.user.last_name}}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="appointment.worker" class="mb-5">
          <h5 class="mb-3">Assigned Worker</h5>
          <div class="col-12">
            <div class="card">
              <div class="card-body">
                <div class="row">
                  <div class="col-sm-5 col-md-4">
                    <router-link :to="`/users/${appointment.worker.id}`">
                      <div class="d-flex align-items-center">
                        <div class="avatar-xl me-2 rounded-circle flex-shrink-0">
                          <img :src="absoluteUrl(appointment.worker.image)" alt="" class="member-img img-fluid d-block rounded-circle">
                        </div>
                        <div>
                          <h6 class="mb-1">{{appointment.worker.mobile}}</h6>
                          <p class="mb-0">{{appointment.worker.first_name}} {{appointment.worker.last_name}}</p>
                          <p class="mb-0 small">Type: <strong class="text-uppercase"> {{appointment.worker.role}}</strong></p>
                        </div>
                      </div>
                    </router-link>
                  </div>
                </div>
              </div> 
            </div>
          </div>
        </div>
        <div class="row gy-2 mb-4">
          <div v-if="appointment.address" class="col-lg-6">
            <address-card :item="appointment.address" />
          </div>
          <div class="col-lg-6">
            <div class="card">
              <div class="card-body">
                <div>
                  <h5 class="border-bottom pb-2">Pricing details </h5>
                  <div class="">
                    <div class="d-flex align-items-center justify-content-between">
                      <span class="mb-1" v-if="appointment.repair">{{appointment.repair.title}} cost</span>
                      <h6 class="mb-0">${{ $filters.money_format(appointment.service_cost)}}</h6>
                    </div>
                    <div class="mt-2 d-flex align-items-center justify-content-between">
                      <span class="mb-1">Convenience fee</span>
                      <h6 class="mb-0">${{ $filters.money_format(appointment.convenience_fee)}}</h6>
                    </div>
                  </div> 
                </div>
                <div class="bd-callout small p-2 mt-3 mb-0 bd-callout-info">
                  Repair option: <strong class="text-uppercase">{{appointment.repair_option.replace(/_/g, ' ')}}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h5 class="mb-3">Invoice History</h5>
        <div class="card mb-5">
          <div class="card-body">
            <invoice-table :items="appointment.invoices" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <is-loading v-else  />
  <b-modal hide-footer v-model="popupModalUpdate" @hide="initializeAll()" title="Update appointment">
    <appointment-form v-if="popupModalUpdate" @closeMe="initializeAll()"
      :editItem="appointment" @updateItem="updateItem($event)"/>
  </b-modal>

  <b-modal hide-footer v-model="popupModalAssign" @hide="initializeAll()" title="Assign technician">
    <worker-assign-form v-if="popupModalAssign" @closeMe="initializeAll()"
      :editItem="appointment" :worker-list="workers" @updateItem="updateItem($event)"/>
  </b-modal>

  <b-modal hide-footer v-model="modalCreditCard" @hide="initializeAll()" title="Edit Credit Card">
    <stripe-payment-form :config="stripeSetup" v-if="modalCreditCard"
      @setupConfirmed="updatePaymentMethod($event)" btnText="Update Payment Method" />
  </b-modal>

  <b-modal hide-footer v-model="popupCashModal" @hide="initializeAll()" title="Enter Payment details">
    <cash-payment-form :config="cashConfig" @addedItem="addInvoice($event)"
     v-if="popupCashModal" @closeMe="initializeAll()"/>
  </b-modal>
</div>
</template>

<script>
import IsLoading from '@/components/IsLoading.vue'
import AppointmentForm from '@/components/forms/AppointmentForm.vue'
import InvoiceTable from '@/components/tables/InvoiceTable.vue'
import PaymentMethodCard from '@/components/cards/PaymentMethodCard.vue'
import WorkerAssignForm from '@/components/forms/WorkerAssignForm.vue'
import AddressCard from '@/components/cards/AddressCard.vue'
import StripePaymentForm from '@/components/forms/StripePaymentForm.vue'
import CashPaymentForm from '@/components/forms/CashPaymentForm.vue'

export default {
  components: { 
    IsLoading,
    AppointmentForm,
    InvoiceTable,
    PaymentMethodCard,
    WorkerAssignForm,
    AddressCard,
    StripePaymentForm,
    CashPaymentForm
  },
  data(){
    return {
      isLoading: true,
      appointment: null,
      popupModalUpdate: false,
      popupModalAssign: false,
      modalCreditCard: false,
      stripeSetup: null,
      workers: [],
      popupCashModal: false,
      cashConfig: null
    }
  },
  watch: {
    '$route.params.appointId': function (id){
      if(id) this.fetchAppointment()
    },
  },
  methods:{
    updateItem(item){
      Object.assign(this.appointment, item)
    },
    fetchAppointment(){
      this.isLoading = true
      this.$http.get(`/appointments/${this.$route.params.appointId}/show`)
      .then((response) => {
        this.isLoading =  false
        if(response.data.success){
          this.appointment = response.data.data
        }else{
          this.$router.push({path: '/error-404'})
        }
      })
    },
    async getStripeSetup(){
      this.loadStripe()
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.get(this.absoluteUrl('/api/stripe/setup'))
        .then(response => {
        this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.stripeSetup = response.data.data.config
            this.modalCreditCard = true
          }
      })
    },
    updatePaymentMethod(setup){
      let formData = new FormData();
      formData.append('setup_id', setup.id);
      formData.append('payment_method', setup.payment_method);
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.post(`/appointments/${this.appointment.id}/payment-method`, formData)
        .then(response => {
        this.$store.dispatch("changeLoaderValue")
        if(response.data.success){
          this.updateItem(response.data.data)
          this.modalCreditCard = false
        }
      })
    },
    assignWorker(){
      this.$store.dispatch("changeLoaderValue", true)
      this.$http.get('/users/workers')
        .then(response => {
        this.$store.dispatch("changeLoaderValue")
          if(response.data.success){
            this.workers = response.data.data
            this.popupModalAssign = true
          }
      })
    },
    collectPayment(){
      if(!this.appointment.payment_method){
        this.alertError('No Payment method! Please update payment method'); return
      }
      this.confirmAction({
        text: `You are about to charge a service amount of: <strong>$<span>${this.appointment.service_cost}</span> </strong>`,
      }).then((result) => {
        if (result.value) { 
          this.$store.dispatch("changeLoaderValue", true)
          this.$http.post(`/appointments/${this.appointment.id}/collect-payment`, {})
            .then(response => {
            this.$store.dispatch("changeLoaderValue")
            if(response.data.success){
              this.updateItem(response.data.data)
            }
          })
        }
      });
    },
    initCashPayment(){
      this.cashConfig = {
        appointment_id:  this.appointment.id,
        currency: 'usd',
        payvia: "CASH",
        paymentid: 'none',
        status: "paid_cash",
        user_id: this.appointment.user.id
      }
      this.popupCashModal = true
    },
    addInvoice(item){
      this.appointment.invoices.push(item);
    },
    deleteItem() {
      this.confirmDelete({
        text: "You are about to delete this appointment",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("changeLoaderValue",true)
          this.$http.delete(`/appointments/${this.appointment.id}/delete`)
          .then(response => {
            this.$store.dispatch("changeLoaderValue",false)
            if(response.data.success){
              this.$router.push({path: '/appointments'})
            }
          })
        }
      });
    },
    initializeAll(){
      this.popupModalUpdate = false
      this.popupModalAssign = false
      this.modalCreditCard = false
      this.popupCashModal = false
      this.cashConfig = null
    }
  },
  created(){
    this.fetchAppointment()
  }
}

</script>

<style lang="scss">

</style>
