<template>
     <form autocomplete="off" class="">
        <div class="row g-3">
            <div class="col-md-6">
                <label for="inputAmount">
                    Amount Collected (<span class="text-uppercase">{{ data.currency }}</span>) <span class="text-danger">*</span>
                </label>
                <input type="number" v-model="data.amount" class="form-control"
                name="amount" id="inputAmount" :class="{ 'is-invalid': v$.data.amount.$error }" placeholder="Enter Amount" />
                <div v-for="(item, index) in v$.data.amount.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div>
            <div class="col-lg-6">
                <label for="inputStatus">
                    Status <span class="text-danger small">*</span>
                </label>
                <input disabled type="text" v-model="data.status" id="inputStatus" name="name" class="form-control"
                    placeholder="Invoice status" />
            </div>
            <div class="col-lg-12">
                <label for="inputStatus">
                    Payment Id <span class="text-danger small">*</span>
                </label>
                <input type="text" v-model="data.paymentid" id="inputStatus" name="name" class="form-control"
                    placeholder="Invoice status" />
            </div>
            <div class="col-12">
                <label class="form-label" for="description">Description</label>
                <textarea id="description"
                    v-model="data.description" class="form-control" rows="3"
                    placeholder="Write description" name="description"></textarea>
            </div>
            <div class="col-12">
                <div class="d-flex justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="confirmCreate()"
                     type="button">Submit </b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers, requiredIf} from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        config:{
            type: Object,
            default: null
        },
    },
    data(){
        return{
            data: {
                amount: "",
                description: ""
            }
        }
    },
    validations() {
        return {
            data:{
                amount:{ required: required},
            }
        }
    },
    methods:{
        confirmCreate(){
            this.v$.$validate().then(result =>{
                if (!result){ this.alertError('Form not valid'); return;}
                this.confirmAction({
                text: `You are about to create a paid invoice that the sum of <strong> ${this.data.amount} 
                    <span class='text-uppercase'>${this.data.currency}</span></strong> was paid in cash`,
                }).then((result) => {
                    if (result.value) { this.create(); }
                });
            })
        },
        create() {
            const formData = new FormData()
            formData.append('data', JSON.stringify(this.data));
            this.$store.dispatch("changeLoaderValue", true)
            this.$http.post(`/invoices/create`, formData)
            .then((response) => {
                this.$store.dispatch("changeLoaderValue")
                if(response.data.success){
                    this.$emit('addedItem', response.data.data)
                    this.resetForm();
                }
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        },
    },
    mounted(){
        this.data = Object.assign(this.data, this.config)
    }
}
</script>