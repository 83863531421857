<template>
    <form autocomplete="off" class="">
        <div v-if="editItem.worker" class="bd-callout small p-2 my-0 bd-callout-warning">
            Note: This assignment will override <strong class="text-uppercase">{{editItem.worker.role}} - {{editItem.worker.first_name}}</strong>
        </div>
        <div class="row gy-3 mt-2">
            <div class="col-12">
                <div class="d-flex">
                <input v-model="workerType" type="radio" class="btn-check"
                 value="technician"  name="worker_type" id="technicians" autocomplete="off">
                <label class="btn flex-grow-1 btn-outline-primary me-3" for="technicians">Technicians</label>

                <input v-model="workerType" type="radio" class="btn-check" 
                value="partner" name="worker_type" id="partners" autocomplete="off">
                <label class="btn flex-grow-1 btn-outline-primary" for="partners">Partners</label>
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">
                    Worker <span class="text-danger">*</span>
                </label>
                <select class="form-select" v-model="data.worker_id"
                    name="worker_id" placeholder="--select--">
                    <option disabled :value="null">--select {{workerType}} --</option>
                    <option v-for="worker in workers" :key="worker.id"
                        :value="worker.id">{{worker.first_name}} {{worker.last_name}} - {{worker.email}}</option>
                </select>
                <div v-for="(item, index) in v$.data.worker_id.$errors" :key="index"
                    class="invalid-feedback">
                    <span v-if="item.$message">{{ item.$message }}</span>
                </div>
            </div> 
            <div class="col-12">
                <div class="d-flex mt-3 justify-content-end">
                    <b-button variant="primary" 
                    @click.prevent="update()"
                     type="button">Update</b-button>
                </div>
            </div>
        </div>
    </form>
</template>

<script>
import { required, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";

export default {
    setup() {
        return { v$: useVuelidate() };
    },
    props:{
        editItem:{
            type: Object,
            default: null
        },
        editMode:{
            type: Boolean,
            default: true
        },
        workerList:{
            default:()=> ([])
        }
    },
    data(){
        return{
            data: {
                worker_id: null,
            },
            workerType: 'technician'
        }
    },
    validations() {
        return {        
            data:{
                worker_id: { required: required},            
            }
        }
    },
    watch: {
        workerType (val) {
            this.data.worker_id = null
        }
    },
    computed:{
        workers(){
            return this.workerList.filter((i) => i.role == this.workerType)
        }
    },
    methods:{
        update(){
             this.v$.$validate().then(result =>{
                if (!result) { this.alertError(this.v$.$errors[0].$message); return };
                this.$store.dispatch("changeLoaderValue", true)
                this.$http.post(`/appointments/${this.editItem.id}/assign-worker?worker_id=${this.data.worker_id}`)
                    .then((response) => {
                        this.$store.dispatch("changeLoaderValue", false)
                        if(response.data.success){
                            this.$emit('updateItem', response.data.data)
                            this.resetForm()
                        }
                    })
            
            })
        },
        resetForm(){
            this.v$.$reset()
            this.$emit("closeMe")
        }
    },
    mounted(){
        if(this.editMode){
            this.data = this.$filters.patchUpdatables(this.data, this.editItem)
        }
    }
}
</script>